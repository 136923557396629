import './Publications.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser'

const Publications = ({ papers }) => {
  // Group papers by year
  const groupedPapers = papers.reduce((acc, paper) => {
    if (!acc[paper.year]) {
      acc[paper.year] = []
    }
    acc[paper.year].push(paper)
    return acc
  }, {})

  // Sort years in descending order
  const sortedYears = Object.keys(groupedPapers).sort((a, b) => b - a)

  return (
    <div className='about__details'>
      <section id='publications' className='section publications'>
        <h2 className='section__title'>Publications</h2>
        {sortedYears.map((year) => (
          <div key={year} className='publication__year-group'>
            <h3 className='publication__year-heading'>{year}</h3>
            <ul className='publications__list'>
              {groupedPapers[year].map((paper) => (
                <li key={paper.title} className='publication__item'>
                  <a
                    href={paper.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='publication__title'
                  >
                    {paper.title}
                  </a>
                  <span className='publication__authors'>
                    , {parse(paper.authors)}{' '}
                  </span>
                  <span className='publication__conference'>
                    {' '}
                    ({paper.conference})
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>
    </div>
  )
}

export default Publications
