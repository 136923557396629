import uniqid from 'uniqid'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'

const Projects = ({ projects }) => {
  if (!projects.length) return null

  return (
    <div className='about__details'>
      <section id='projects' className='section projects'>
        <h2 className='section__title'>Featured Projects</h2>

        <div className='projects__grid'>
          {projects.map((project) => (
            <ProjectContainer key={uniqid()} project={project} />
          ))}
        </div>
      </section>
    </div>
  )
}

export default Projects
